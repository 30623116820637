import React from 'react'
import { graphql, Link } from 'gatsby'
import { Billboard, Wrapper, Modal, SEO, Button } from '@renderbus/common/components'
import {
  BannerContainer,
  BannerTitle,
  PlayIconBox,
  WarpperContainer,
  WrapperTitle,
  PerformanceContainer,
  PerformanceContent,
  PerformanceTitle,
  PerformanceInfo,
  AutoContainer,
  AutoImgBox,
  AutoHalfBox,
  AutoIconRow,
  AutoIconBox,
  AutoIconContent,
  AutoIconTitle,
  AutoIconSubTitle,
  PipelineContainer,
  PipelineContent,
  PipelineTitle,
  PipelineInfo,
  ProcessContainer,
  ProcessBox,
  ProcessTitle,
  CustomizeContainer,
  CustomizeBox,
  CustomizeTitle,
  QingyunInfoContainer,
  QingyunPriceText,
  QingyunRound,
  QingyunRoundBottom,
  QingyunContainer,
  MobileBr,
  AdvertisingSpaceWrap,
  VideoWrap,
} from './film-tv-rendering.atom'

import Layout from '../molecules/layout'
import RegisterBanner from '../molecules/register-banner'
import TeamSwiper from '../molecules/file-tv-rendering/team-swiper'

import playIcon from '../images/architectural-rendering/xgt_play.png'
import playIconHover from '../images/architectural-rendering/xgt_play_hover.png'
import CPUIcon from '../images/film-tv-rendering/CPU-icon.png'
import PerformanceIcon1 from '@renderbus/common/components/svg/film-01'
import PerformanceIcon2 from '@renderbus/common/components/svg/film-02'
import PerformanceIcon3 from '@renderbus/common/components/svg/film-03'
import AutoIcon1 from '@renderbus/common/components/svg/film-04'
import AutoIcon2 from '@renderbus/common/components/svg/film-05'
import AutoIcon3 from '@renderbus/common/components/svg/film-06'
import AutoIcon4 from '@renderbus/common/components/svg/film-07'
import AutoIcon5 from '@renderbus/common/components/svg/film-08'
import Pipeline1 from '@renderbus/common/components/svg/film-09'
import Pipeline2 from '@renderbus/common/components/svg/film-10'
import Process1 from '@renderbus/common/components/svg/film-11'
import Process2 from '@renderbus/common/components/svg/film-12'
import Process3 from '@renderbus/common/components/svg/film-13'
import Process4 from '@renderbus/common/components/svg/film-14'
import Process5 from '@renderbus/common/components/svg/film-15'
import Customize1 from '@renderbus/common/components/svg/film-16'
import Customize2 from '@renderbus/common/components/svg/film-17'
import Customize3 from '@renderbus/common/components/svg/film-18'
import Customize4 from '@renderbus/common/components/svg/film-19'
import Customize5 from '@renderbus/common/components/svg/film-20'
import Customize6 from '@renderbus/common/components/svg/film-21'
import { color } from '@renderbus/common/theme'

const autoClientList = [
  {
    title: '一键批量提交',
    subTitle: '批量拖拽式发送多个渲染作业',
    icon: <AutoIcon1 />,
  },
  {
    title: '自动分析资产',
    subTitle: '全自动分析和抽取所需要的资产文件，支持常用的插件缓存文件',
    icon: <AutoIcon2 />,
  },
  {
    title: '分层相机渲染',
    subTitle: '独立控制文件内每个层的渲染，在一个作业里面自由控制多层多相机的渲染',
    icon: <AutoIcon3 />,
  },
  {
    title: '实时预览渲染进度',
    subTitle: '实时预览渲染画面，随时掌控渲染进度',
    icon: <AutoIcon4 />,
  },
  {
    title: '客户端支持Windows、Linux',
    icon: <AutoIcon5 />,
  },
]
const processList = [
  {
    title: '环境准备',
    icon: <Process1 />,
  },
  {
    title: '安全服务协议',
    icon: <Process2 />,
  },
  {
    title: '测试渲染',
    icon: <Process3 />,
  },
  {
    title: '正式渲染',
    icon: <Process4 />,
  },
  {
    title: '交付结案',
    icon: <Process5 />,
  },
]
const customizeList = [
  {
    title: '独立网络',
    icon: <Customize1 />,
  },
  {
    title: '独立渲染机节点',
    icon: <Customize2 />,
  },
  {
    title: '独立存储',
    icon: <Customize3 />,
  },
  {
    title: '定制渲染环境',
    icon: <Customize4 />,
  },
  {
    title: '用户自有插件部署',
    icon: <Customize5 />,
  },
  {
    title: '专属数据同步服务',
    icon: <Customize6 />,
  },
]

class FilmTvRendering extends React.PureComponent {
  state = {
    autoImgFluid: '',
    autoImgIndex: 0,
    playIconSrc: playIcon,
    showVideo: false,
    clientIntroIndex: 0,
  }
  videoRef = null
  componentDidMount() {
    this.setState({
      autoImgFluid: this.props.data.autoClient1.childImageSharp.fluid,
      autoImgIndex: 0,
    })
    this.autoPlay()
  }
  enterAutoRow(index) {
    this.setState({ clientIntroIndex: index })
    this.showCurrentIntro(index)
  }
  HoverPlayIcon(isHover) {
    if (isHover) {
      this.setState({ playIconSrc: playIconHover })
    } else {
      this.setState({ playIconSrc: playIcon })
    }
  }

  ShowVideo() {
    this.setState({ showVideo: true })
    if (this.videoRef) this.videoRef.play()
  }
  CloseVideo(event) {
    if (event) event.preventDefault()
    if (this.videoRef) this.videoRef.pause()
    this.setState({ showVideo: false })
  }

  setVideoRef = ref => {
    this.videoRef = ref
  }
  showCurrentIntro = index => {
    const currentIndex = index === undefined ? this.state.clientIntroIndex : index
    if (this.props.data[`autoClient${currentIndex + 1}`]) {
      this.setState({
        autoImgFluid: this.props.data[`autoClient${currentIndex + 1}`].childImageSharp.fluid,
        autoImgIndex: currentIndex,
      })
    }
  }

  getCurrentIndex = () => {
    if (this.state.clientIntroIndex + 1 > autoClientList.length - 1) {
      this.setState({ clientIntroIndex: 0 })
    } else {
      this.setState({ clientIntroIndex: this.state.clientIntroIndex + 1 })
    }
  }
  autoPlay() {
    this.timer = setInterval(() => {
      this.getCurrentIndex()
      this.showCurrentIntro()
    }, 3000)
  }
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    const { autoImgFluid, showVideo, playIconSrc, clientIntroIndex } = this.state
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='影视云渲染,三维动画云渲染软件,动画影视后期渲染平台-Renderbus'
          keywords='影视渲染,动画渲染'
          description='影视云渲染,三维动画渲染服务专属底价,可基于您的实际需求自由定制渲染服务,奥斯卡渲染服务团队24小时保驾护航'
        />
        <BannerContainer>
          <BannerTitle>
            亚洲知名的云渲染平台
            <br />
            为影视行业提供澎湃算力
          </BannerTitle>
          <PlayIconBox
            onClick={() => {
              this.ShowVideo()
            }}
            onMouseEnter={() => {
              this.HoverPlayIcon(true)
            }}
            onMouseLeave={() => {
              this.HoverPlayIcon(false)
            }}
          >
            <img src={playIconSrc} alt='play' />
            <span>观看教程</span>
          </PlayIconBox>
          <Billboard
            fluid={[
              data.bannerMb.childImageSharp.fluid,
              {
                ...data.banner.childImageSharp.fluid,
                media: `(min-width: 780px)`,
              },
            ]}
          />
        </BannerContainer>
        <AdvertisingSpaceWrap>{/* ad here */}</AdvertisingSpaceWrap>
        <WarpperContainer lighter={true}>
          <Wrapper>
            <WrapperTitle>为何要使用Renderbus渲染影视动画？</WrapperTitle>
            <PerformanceContainer>
              <PerformanceContent>
                <PerformanceIcon1 />
                <PerformanceTitle>高速传输引擎</PerformanceTitle>
                <PerformanceInfo>
                  Renderbus渲染客户端内置世界领先的“镭速”传输引擎，除了能提供超高速传输速度，还能对传输过程中的数据进行加密，保证数据安全。
                </PerformanceInfo>
              </PerformanceContent>
              <PerformanceContent>
                <PerformanceIcon2 />
                <PerformanceTitle>高性能存储</PerformanceTitle>
                <PerformanceInfo>
                  基于纯SSD固态硬盘的分布式存储拥有超高的io性能，当成百上千台服务器同时加载源文件进行渲染时，都能像加载本地磁盘上的文件一样迅速。
                </PerformanceInfo>
              </PerformanceContent>
              <PerformanceContent>
                <PerformanceIcon3 />
                <PerformanceTitle>海量机器秒级调度</PerformanceTitle>
                <PerformanceInfo>
                  调度引擎能轻松管理上百万的渲染帧队列，除了能根据优先级进行调度之外，自动识别用户文件中用到的软件插件，并匹配到对应的节点机上。
                </PerformanceInfo>
              </PerformanceContent>
            </PerformanceContainer>
          </Wrapper>
        </WarpperContainer>
        <WarpperContainer>
          <Wrapper
            onMouseEnter={() => clearInterval(this.timer)}
            onMouseLeave={() => this.autoPlay()}
          >
            <WrapperTitle>全自动化客户端</WrapperTitle>
            <AutoContainer>
              <AutoImgBox
                fluid={autoImgFluid || data.autoClient1.childImageSharp.fluid}
                imgStyle={{ height: 'auto' }}
                alt='影视云渲染软件客户端功能'
              />
              <AutoHalfBox>
                {autoClientList.map((row, index) => {
                  return (
                    <AutoIconRow
                      key={index}
                      active={index === clientIntroIndex}
                      onMouseEnter={() => {
                        this.enterAutoRow(index)
                      }}
                    >
                      <AutoIconBox>{row.icon}</AutoIconBox>
                      <AutoIconContent>
                        <AutoIconTitle>{row.title}</AutoIconTitle>
                        <AutoIconSubTitle>{row.subTitle}</AutoIconSubTitle>
                      </AutoIconContent>
                    </AutoIconRow>
                  )
                })}
              </AutoHalfBox>
            </AutoContainer>
          </Wrapper>
        </WarpperContainer>
        <WarpperContainer lighter={true}>
          <Wrapper>
            <WrapperTitle>无缝集成到pipeline</WrapperTitle>
            <PipelineContainer>
              <PipelineContent>
                <Pipeline1 />
                <PipelineTitle>强大的Python SDK</PipelineTitle>
                <PipelineInfo>
                  功能完备的Python
                  SDK，涵盖多种二次开发的需求，具备易集成，高可靠的特点，几行代码，即可轻松控制Renderbus云渲染
                </PipelineInfo>
              </PipelineContent>
              <PipelineContent>
                <Pipeline2 />
                <PipelineTitle>轻松嵌入在流程的任何地方</PipelineTitle>
                <PipelineInfo>
                  无论是在Maya菜单，还是在Shotgun工作页面，还是在你本地的Deadline渲染管理软件，都可以无缝集成Renderbus云渲染，轻松实现自动提交渲染作业，实时监控渲染进度
                </PipelineInfo>
              </PipelineContent>
            </PipelineContainer>
          </Wrapper>
        </WarpperContainer>
        <WarpperContainer>
          <Wrapper>
            <WrapperTitle>服务流程</WrapperTitle>
            <ProcessContainer>
              {processList.map((process, index) => {
                return (
                  <ProcessBox key={index}>
                    {process.icon}
                    <ProcessTitle>{process.title}</ProcessTitle>
                  </ProcessBox>
                )
              })}
            </ProcessContainer>
          </Wrapper>
        </WarpperContainer>
        <WarpperContainer lighter={true}>
          <Wrapper>
            <WrapperTitle>完全可定制化的服务</WrapperTitle>
            <CustomizeContainer>
              {customizeList.map((customize, index) => {
                return (
                  <CustomizeBox key={index}>
                    {customize.icon}
                    <CustomizeTitle>{customize.title}</CustomizeTitle>
                  </CustomizeBox>
                )
              })}
            </CustomizeContainer>
          </Wrapper>
        </WarpperContainer>
        <WarpperContainer>
          <Wrapper>
            <WrapperTitle>大客户服务团队为您提供灵活的渲染解决方案</WrapperTitle>
            <TeamSwiper />
          </Wrapper>
        </WarpperContainer>
        <WarpperContainer lighter={true}>
          <Wrapper>
            <QingyunContainer>
              <QingyunInfoContainer>
                <h2>CPU青云平台超值渲染</h2>
                <p>
                  青云平台是专为中小型动画工作室打造的“轻量实惠版”云渲染平台，让用户享受更优惠的价格，使用专业的云渲染服务。
                </p>
                <QingyunPriceText>
                  CPU渲染低至<strong>0.03125</strong>元/核时
                </QingyunPriceText>
                <Button backgroundColor={color.primary} as={Link} to='/go-cloud.html'>
                  了解详情
                </Button>
              </QingyunInfoContainer>
              <QingyunRound>
                <img src={CPUIcon} alt='cpu' />
                <h5>0.03125</h5>
                <QingyunRoundBottom>元/核时</QingyunRoundBottom>
              </QingyunRound>
            </QingyunContainer>
          </Wrapper>
        </WarpperContainer>
        <RegisterBanner />
        <Modal
          simpleHeader
          bodyStyle={{ padding: '0' }}
          visible={showVideo}
          onClose={e => {
            this.CloseVideo(e)
          }}
          darkTheme={true}
        >
          <VideoWrap>
            <video
              src='https://videos.ctfassets.net/lj6m41xoeoiw/6in9kMiV4e63fdpBSUNeuc/99a64abbbf74eecc6c968caef546e338/Maya_Client_submit_video-v4.mp4'
              allowFullScreen={true}
              controls={true}
              autoPlay={true}
              title='client-maya'
              ref={this.setVideoRef}
            />
          </VideoWrap>
        </Modal>
      </Layout>
    )
  }
}

export default FilmTvRendering

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/film-tv-rendering/bg.png/" }) {
      ...fluidImage
    }
    bannerMb: file(relativePath: { regex: "/film-tv-rendering/bg_mobile.png/" }) {
      ...fluidImage
    }
    autoClient1: file(relativePath: { regex: "/film-tv-rendering/autoClient1.png/" }) {
      ...fluidImage
    }
    autoClient2: file(relativePath: { regex: "/film-tv-rendering/autoClient2.png/" }) {
      ...fluidImage
    }
    autoClient3: file(relativePath: { regex: "/film-tv-rendering/autoClient3.png/" }) {
      ...fluidImage
    }
    autoClient4: file(relativePath: { regex: "/film-tv-rendering/autoClient4.png/" }) {
      ...fluidImage
    }
    autoClient5: file(relativePath: { regex: "/film-tv-rendering/autoClient5.png/" }) {
      ...fluidImage
    }
  }
`
